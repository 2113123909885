.App{
font-family: 'Poppins', sans-serif;
overflow-x: hidden;
margin-top: 8vh;
}

.react-tel-input .form-control {
  width: 100%;
}

a{
  text-decoration: none;
}
.header{
  background-color: #0a1435
}
img {
overflow-clip-margin: content-box;
overflow: clip;
}
.base-container{
min-height: 90vh;
}

/* Scroll Bar */
::-webkit-scrollbar{
  display: none;
}
.footer{
background-color: #0a1435;
height: 40vh;
/* height: auto; */
color: rgb(154, 196, 232);
bottom:0;
padding: 0 5vh;
}
.footer-container{
  transform: translateY(50%);
  margin-top: 2vh;
}
a:link { text-decoration: none; }

.contact-us-short{
/* background-color: #f9f9ff; */
background-color: #bfcaf0;
transform: translateY(50%);
min-height: 5vh;
max-width: 50vw;
}

.slider{
    color: black;
    background-color: #f9f9ff;
}
.img-fit {
    /* aspect-ratio: 20/11; */
    object-fit:fill;
    max-height:25vh;
  }
  .card-hover:hover{
    transform: scale(101%);
    box-shadow: 2px 1px #afafb6bd;
  }
  @media (max-width: 767.98px) {
    .img-fit {
      aspect-ratio: 1.45/1;
    }
    .contact-us-short{
        max-width: 90vw;
    }
    .footer{
      padding: 0 1vh;
    }
  }
  @media only screen and (max-width: 430px) {
    .contact-us-short{
        max-width: 98vw;
    }
    }

    @media only screen and (max-width: 576px) {
      .contact-us-short{
          max-width: 98vw;
      }
      .footer{
        font-size:small;
        height:50vh
      }
      }
    
      @media only screen and (max-width: 300px) {
        .footer{
          font-size:x-small;
        }
        }